import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Table,
} from 'reactstrap'
import _ from 'lodash'
import { List } from 'immutable'

import SelectBar from 'components/SelectBar'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import SectionHeader from 'components/SectionHeader'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import MarkdownEditor from 'components/MarkdownEditor'
import FormLabel from 'components/FormLabel'
import { selectControlGroups } from 'containers/ControlFrameworkData/selectors'
import { SONRAI_ORG_NAME } from 'appConstants'

import { updateObjective, createObjective } from './actions'

export const CreateEditModal = ({
  toggle,
  editingObjective = {},
  createObjective,
  updateObjective,
  controlFrameworks,
}) => {
  const [name, setName] = useState(editingObjective.name)
  const [description, setDescription] = useState(
    editingObjective.description || ''
  )
  const [frameworks, setFrameworks] = useState(
    _.get(editingObjective, ['definedControlFrameworks'], [])
  )
  const [addingCf, setAddingCf] = useState(null)

  const isEditing = !_.isEmpty(editingObjective)

  const saveObjective = () => {
    if (isEditing) {
      updateObjective({
        name,
        description,
        srn: editingObjective.srn,
        definedControlFrameworks: frameworks,
      })
    } else {
      createObjective({
        name,
        description,
        definedControlFrameworks: frameworks,
      })
    }

    toggle()
  }

  const moveFrameworkUp = fromIndex => {
    if (fromIndex === 0) {
      return
    }

    const orderArray = [...frameworks]
    const ele = orderArray[fromIndex]
    orderArray.splice(fromIndex, 1)
    orderArray.splice(fromIndex - 1, 0, ele)

    setFrameworks(orderArray)
  }

  const moveFrameworkDown = fromIndex => {
    if (fromIndex === frameworks.length - 1) {
      return
    }

    const orderArray = [...frameworks]
    const ele = orderArray[fromIndex]
    orderArray.splice(fromIndex, 1)
    orderArray.splice(fromIndex + 1, 0, ele)

    setFrameworks(orderArray)
  }

  const removeFramework = fromIndex => {
    const orderArray = [...frameworks]
    orderArray.splice(fromIndex, 1)

    setFrameworks(orderArray)
  }

  const addControlFramework = () => {
    const orderArray = [...frameworks]
    orderArray.push({
      controlFrameworkSrn: addingCf,
      controlFrameworkOrder: frameworks.length,
    })

    setFrameworks(orderArray)
    setAddingCf(null)
  }

  return (
    <Modal isOpen={true} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {isEditing ? 'Edit Objective' : 'Create Objective'}
      </ModalHeader>
      <ModalBody>
        <SectionHeader small>Details</SectionHeader>
        <div>
          <FormLabel required>Objective Name</FormLabel>
          <Input value={name} onChange={e => setName(e.target.value)} />
        </div>

        <div>
          <FormLabel>Description</FormLabel>
          <MarkdownEditor
            value={description}
            onChange={value => setDescription(value)}
          />
        </div>

        <SectionHeader small style={{ marginTop: '3em' }}>
          Control Frameworks
        </SectionHeader>
        <p>
          * At least one Control Framework must be added to an Objective to make
          it visible in the Swimlane Checkup
        </p>
        <Table>
          <thead>
            <tr>
              <th>Title</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {frameworks.map((frameworkConf, index) => {
              const framework = controlFrameworks.get(
                frameworkConf.controlFrameworkSrn
              )
              return (
                <tr key={frameworkConf.controlFrameworkSrn}>
                  <td>{framework.get('title')}</td>
                  <td>
                    <BorderlessButton
                      color="primary"
                      disabled={index === 0}
                      onClick={() => moveFrameworkUp(index)}
                      title="Reorder Up"
                    >
                      <Icon fa name="arrow-up" />
                    </BorderlessButton>
                  </td>
                  <td>
                    <BorderlessButton
                      color="primary"
                      disabled={index === frameworks.length - 1}
                      onClick={() => moveFrameworkDown(index)}
                      title="Reorder Down"
                    >
                      <Icon fa name="arrow-down" />
                    </BorderlessButton>
                  </td>
                  <td>
                    <BorderlessButton
                      color="primary"
                      onClick={() => removeFramework(index)}
                      title="Remove"
                    >
                      <Icon fa name="trash-alt" />
                    </BorderlessButton>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gridColumnGap: '1em',
          }}
        >
          <SelectBar
            isClearable
            value={addingCf}
            onChange={selectedOption => {
              if (selectedOption) {
                setAddingCf(selectedOption.value)
              } else {
                setAddingCf(null)
              }
            }}
            options={controlFrameworks
              .filterNot(
                cf =>
                  frameworks.find(
                    conf => conf.controlFrameworkSrn === cf.get('srn')
                  ) ||
                  (cf
                    .get('resourceId', '')
                    .startsWith(`/org/${SONRAI_ORG_NAME}`) &&
                    !cf.getIn(['clonedBy', 'items'], List()).isEmpty())
              )
              .toList()
              .toJS()
              .map(cf => ({ label: cf.title, value: cf.srn }))}
          />
          <Button
            disabled={!addingCf}
            outline
            color="primary"
            onClick={addControlFramework}
          >
            Add Framework
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <TextLink color="secondary" onClick={toggle}>
          Cancel
        </TextLink>
        <Button color="primary" onClick={saveObjective}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

CreateEditModal.propTypes = {
  controlFrameworks: ImmutablePropTypes.map.isRequired,
  createObjective: PropTypes.func.isRequired,
  editingObjective: PropTypes.object,
  toggle: PropTypes.func,
  updateObjective: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  controlFrameworks: selectControlGroups,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateObjective,
      createObjective,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(CreateEditModal)
