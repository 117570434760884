import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  UPDATE_OBJECTIVE,
  UPDATE_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE_ERROR,
  CREATE_OBJECTIVE,
  CREATE_OBJECTIVE_SUCCESS,
  CREATE_OBJECTIVE_ERROR,
} from './constants'

const initialState = fromJS({
  creating: false,
  updating: null,
})

const objectiveManagerReducer = handleActions(
  {
    [CREATE_OBJECTIVE]: state => state.set('creating', true),
    [CREATE_OBJECTIVE_SUCCESS]: state => state.set('creating', false),
    [CREATE_OBJECTIVE_ERROR]: state => state.set('creating', false),
    [UPDATE_OBJECTIVE]: (state, { payload }) =>
      state.set('updating', payload.srn),
    [UPDATE_OBJECTIVE_SUCCESS]: state => state.set('updating', null),
    [UPDATE_OBJECTIVE_ERROR]: state => state.set('updating', null),
  },
  initialState
)

export default objectiveManagerReducer
