import {
  UPDATE_OBJECTIVE,
  UPDATE_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE_ERROR,
  CREATE_OBJECTIVE,
  CREATE_OBJECTIVE_SUCCESS,
  CREATE_OBJECTIVE_ERROR,
  DELETE_OBJECTIVE,
  DELETE_OBJECTIVE_ERROR,
  DELETE_OBJECTIVE_SUCCESS,
  RERANK_OBJECTIVE,
  RERANK_OBJECTIVE_ERROR,
  RERANK_OBJECTIVE_SUCCESS,
} from './constants'
import { createAction } from 'redux-actions'

export const createObjective = createAction(CREATE_OBJECTIVE)
export const createObjectiveSuccess = createAction(CREATE_OBJECTIVE_SUCCESS)
export const createObjectiveError = createAction(CREATE_OBJECTIVE_ERROR)
export const updateObjective = createAction(UPDATE_OBJECTIVE)
export const updateObjectiveSuccess = createAction(UPDATE_OBJECTIVE_SUCCESS)
export const updateObjectiveError = createAction(UPDATE_OBJECTIVE_ERROR)
export const deleteObjective = createAction(DELETE_OBJECTIVE)
export const deleteObjectiveSuccess = createAction(DELETE_OBJECTIVE_SUCCESS)
export const deleteObjectiveError = createAction(DELETE_OBJECTIVE_ERROR)
export const rerankObjective = createAction(RERANK_OBJECTIVE)
export const rerankObjectiveSuccess = createAction(RERANK_OBJECTIVE_SUCCESS)
export const rerankObjectiveError = createAction(RERANK_OBJECTIVE_ERROR)
