import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'

import CreatedByBadge from 'components/CreatedByBadge'
import BarLoadingAnimation from 'components/BarLoadingAnimation'
import TextLink from 'components/TextLink'
import DataTable from 'components/DataTable'
import BorderedCard from 'components/BorderedCard'
import Button from 'components/Button'
import Icon from 'components/Icon'
import {
  selectObjectives,
  selectObjectivesLoading,
  selectSonraiUsers,
} from 'containers/SonraiData/selectors'
import { fetchObjectives } from 'containers/SonraiData/actions'
import BorderlessButton from 'components/BorderlessButton'
import { selectLoadedControlGroups } from 'containers/ControlFrameworkData/selectors'
import permissionChecker from 'containers/PermissionChecker'

import { OBJECTIVE_RANK } from './constants'
import sagas from './sagas'
import reducer from './reducer'
import CreateEditModal from './CreateEditModal'
import { createObjective, deleteObjective, rerankObjective } from './actions'

class ObjectivesManager extends React.Component {
  constructor(props) {
    super(props)

    props.fetchObjectives()

    this.state = {
      createModalOpen: false,
      editingObjective: undefined,
      deletingObjective: null,
    }

    this.styles = {
      container: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '45px 1fr',
        gridTemplateAreas: '"title" "objectives"',
        gridColumnGap: '1em',
        gridRowGap: '0.5em',
        padding: '1em',
        height: '100%',
        overflow: 'auto',
      },
      titleContainer: {
        gridArea: 'title',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
      },
      pageTitle: {
        fontSize: '1.5em',
      },
      objectivesContainer: {
        gridArea: 'objectives',
      },
    }
  }

  closeCreateModal = () => {
    this.setState({ createModalOpen: false, editingObjective: undefined })
  }

  openCreateModal = () => {
    this.setState({ createModalOpen: true })
  }

  openEditModal = row => {
    this.setState({ createModalOpen: true, editingObjective: row.objective })
  }

  cloneObjective = obj => {
    this.props.createObjective({
      name: obj.name,
      description: obj.description,
      definedControlFrameworks: obj.definedControlFrameworks,
    })
  }

  rankObjective = (objectiveSrn, rankAction) => {
    const currentIndex = this.props.objectives.findIndex(
      obj => obj.get('srn') === objectiveSrn
    )

    switch (rankAction) {
      case OBJECTIVE_RANK.FIRST:
        this.props.rerankObjective({
          objectiveSrnToMove: objectiveSrn,
          relativeObjectiveSrn: this.props.objectives.first().get('srn'),
          rerankType: 'BEFORE_RELATIVE_OBJECTIVE',
        })
        return
      case OBJECTIVE_RANK.UP:
        if (currentIndex <= 0) {
          return
        }

        this.props.rerankObjective({
          objectiveSrnToMove: objectiveSrn,
          relativeObjectiveSrn: this.props.objectives
            .get(currentIndex - 1)
            .get('srn'),
          rerankType: 'BEFORE_RELATIVE_OBJECTIVE',
        })
        return
      case OBJECTIVE_RANK.DOWN:
        if (
          currentIndex < 0 ||
          currentIndex === this.props.objectives.size - 1
        ) {
          return
        }

        this.props.rerankObjective({
          objectiveSrnToMove: objectiveSrn,
          relativeObjectiveSrn: this.props.objectives
            .get(currentIndex + 1)
            .get('srn'),
          rerankType: 'AFTER_RELATIVE_OBJECTIVE',
        })
        return
      case OBJECTIVE_RANK.LAST:
        this.props.rerankObjective({
          objectiveSrnToMove: objectiveSrn,
          relativeObjectiveSrn: this.props.objectives.last().get('srn'),
          rerankType: 'AFTER_RELATIVE_OBJECTIVE',
        })
        return
    }
  }

  showDeleteConfirm = srn => {
    this.setState({
      deletingObjective: srn,
    })
  }

  cancelDeleting = () => {
    this.setState({
      deletingObjective: null,
    })
  }

  doDelete = () => {
    this.props.deleteObjective(this.state.deletingObjective)

    this.setState({
      deletingObjective: null,
    })
  }

  getTableData = () => {
    return this.props.objectives.toJS().map((obj, index) => {
      return {
        name: obj.name,
        srn: obj.srn,
        resourceId: obj.resourceId,
        controlFrameworks: obj.definedControlFrameworks.length,
        description: obj.description,
        objective: obj,
        createdBy: obj.createdBy,
        createdByUser: this.props.sonraiUsers.getIn(
          [obj.createdBy, 'name'],
          'Sonrai'
        ),
        actions: obj,
        ranking: index,
      }
    })
  }

  render() {
    const tableData = this.getTableData()

    const canCreate = this.props.userHasPermission({
      permissionName: 'edit.objectives',
    })

    return (
      <div style={this.styles.container}>
        <div style={this.styles.titleContainer}>
          <div style={this.styles.pageTitle}>Manage Objectives</div>
          <div>
            {canCreate && (
              <Button color="primary" onClick={this.openCreateModal}>
                <Icon fa name="plus" />
                &nbsp; Create Objective
              </Button>
            )}
          </div>
        </div>
        <BorderedCard style={this.styles.objectivesContainer}>
          {this.props.objectivesLoading || !this.props.loadedControlGroups ? (
            <BarLoadingAnimation />
          ) : (
            <DataTable
              autosize={false}
              data={tableData}
              expandCollapseColumn="description"
              customColumnConfig={{
                name: {
                  minWidth: 300,
                  width: 300,
                  sortable: false,
                },
                srn: {
                  hide: true,
                  sortable: false,
                },
                resourceId: {
                  hide: true,
                  sortable: false,
                },
                objective: {
                  hide: true,
                  sortable: false,
                },
                controlFrameworks: {
                  sortable: false,
                },
                description: {
                  sortable: false,
                },
                createdBy: {
                  sortable: false,
                  minWidth: 200,
                  width: 200,
                  valueGetter: params => {
                    if (!params.data) {
                      return ''
                    }

                    return params.data.createdByUser
                  },
                  cellRendererFramework: ({ data }) => {
                    if (!data) {
                      return null
                    }
                    return <CreatedByBadge table createdBy={data.createdBy} />
                  },
                },
                createdByUser: {
                  hide: true,
                  sortable: false,
                },
                actions: {
                  hide: !canCreate,
                  sortable: false,
                  cellRendererFramework: params => {
                    if (!params.data) {
                      return null
                    }

                    if (!canCreate) {
                      return null
                    }

                    const canEdit = this.props.userHasPermission({
                      permissionName: 'edit.objectives',
                      resourceId: params.data.resourceId,
                    })

                    return (
                      <div>
                        <BorderlessButton
                          color="primary"
                          title="Edit"
                          disabled={!canEdit}
                          onClick={() => this.openEditModal(params.data)}
                        >
                          <Icon fa name="pencil-alt"></Icon>
                        </BorderlessButton>
                        <BorderlessButton
                          color="primary"
                          title="Clone"
                          onClick={() =>
                            this.cloneObjective(params.data.objective)
                          }
                          style={{ marginLeft: '0.5em' }}
                        >
                          <Icon fa name="copy"></Icon>
                        </BorderlessButton>
                        <BorderlessButton
                          color="primary"
                          title="Delete"
                          disabled={!canEdit}
                          style={{ marginLeft: '0.5em' }}
                          onClick={() =>
                            this.showDeleteConfirm(params.data.srn)
                          }
                        >
                          <Icon fa name="trash-alt"></Icon>
                        </BorderlessButton>
                      </div>
                    )
                  },
                },
                ranking: {
                  sortable: false,
                  cellRendererFramework: params => {
                    if (!params.data) {
                      return null
                    }

                    const canEdit = this.props.userHasPermission({
                      permissionName: 'edit.objectives',
                    })

                    return (
                      <div>
                        <BorderlessButton
                          color="primary"
                          title="Make First"
                          disabled={!canEdit || params.value === 0}
                          onClick={() =>
                            this.rankObjective(
                              params.data.srn,
                              OBJECTIVE_RANK.FIRST
                            )
                          }
                          style={{ marginRight: '0.5em' }}
                        >
                          <Icon fa name="arrow-to-top"></Icon>
                        </BorderlessButton>
                        <BorderlessButton
                          color="primary"
                          title="Move Up One"
                          disabled={!canEdit || params.value === 0}
                          onClick={() =>
                            this.rankObjective(
                              params.data.srn,
                              OBJECTIVE_RANK.UP
                            )
                          }
                          style={{ marginRight: '0.5em' }}
                        >
                          <Icon fa name="arrow-up"></Icon>
                        </BorderlessButton>
                        <BorderlessButton
                          color="primary"
                          title="Move Down One"
                          disabled={
                            !canEdit ||
                            params.value === this.props.objectives.size - 1
                          }
                          onClick={() =>
                            this.rankObjective(
                              params.data.srn,
                              OBJECTIVE_RANK.DOWN
                            )
                          }
                          style={{ marginRight: '0.5em' }}
                        >
                          <Icon fa name="arrow-down"></Icon>
                        </BorderlessButton>
                        <BorderlessButton
                          color="primary"
                          title="Make Last"
                          disabled={
                            !canEdit ||
                            params.value === this.props.objectives.size - 1
                          }
                          onClick={() =>
                            this.rankObjective(
                              params.data.srn,
                              OBJECTIVE_RANK.LAST
                            )
                          }
                          style={{ marginRight: '0.5em' }}
                        >
                          <Icon fa name="arrow-to-bottom"></Icon>
                        </BorderlessButton>
                      </div>
                    )
                  },
                },
              }}
            />
          )}
        </BorderedCard>
        {this.state.createModalOpen && (
          <CreateEditModal
            toggle={this.closeCreateModal}
            editingObjective={this.state.editingObjective}
          />
        )}
        {this.state.deletingObjective && (
          <Modal isOpen={true} toggle={this.cancelDeleting}>
            <ModalHeader toggle={this.cancelDeleting}>
              Confirm Delete
            </ModalHeader>
            <ModalBody>
              Deleting the objective will leave all attached control frameworks
              intact. Continue deletion of this objective?
            </ModalBody>
            <ModalFooter>
              <TextLink onClick={this.cancelDeleting}>Cancel</TextLink>
              <Button color="primary" onClick={this.doDelete}>
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    )
  }
}

ObjectivesManager.propTypes = {
  createObjective: PropTypes.func.isRequired,
  deleteObjective: PropTypes.func.isRequired,
  fetchObjectives: PropTypes.func.isRequired,
  loadedControlGroups: PropTypes.bool,
  objectives: ImmutablePropTypes.list.isRequired,
  objectivesLoading: PropTypes.bool,
  rerankObjective: PropTypes.func.isRequired,
  sonraiUsers: ImmutablePropTypes.map.isRequired,
  userHasPermission: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  objectives: selectObjectives,
  objectivesLoading: selectObjectivesLoading,
  loadedControlGroups: selectLoadedControlGroups,
  sonraiUsers: selectSonraiUsers,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteObjective,
      fetchObjectives,
      createObjective,
      rerankObjective,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'search', reducer })
const withSaga = injectSaga({ key: 'search', saga: sagas })

export default compose(
  withConnect,
  withReducer,
  withSaga,
  permissionChecker
)(ObjectivesManager)
