export const UPDATE_OBJECTIVE = 'app/ObjectiveManager/UPDATE_OBJECTIVE'
export const UPDATE_OBJECTIVE_SUCCESS =
  'app/ObjectiveManager/UPDATE_OBJECTIVE_SUCCESS'
export const UPDATE_OBJECTIVE_ERROR =
  'app/ObjectiveManager/UPDATE_OBJECTIVE_ERROR'
export const CREATE_OBJECTIVE = 'app/ObjectiveManager/CREATE_OBJECTIVE'
export const CREATE_OBJECTIVE_SUCCESS =
  'app/ObjectiveManager/CREATE_OBJECTIVE_SUCCESS'
export const CREATE_OBJECTIVE_ERROR =
  'app/ObjectiveManager/CREATE_OBJECTIVE_ERROR'
export const DELETE_OBJECTIVE = 'app/ObjectiveManager/DELETE_OBJECTIVE'
export const DELETE_OBJECTIVE_SUCCESS =
  'app/ObjectiveManager/DELETE_OBJECTIVE_SUCCESS'
export const DELETE_OBJECTIVE_ERROR =
  'app/ObjectiveManager/DELETE_OBJECTIVE_ERROR'
export const RERANK_OBJECTIVE = 'app/ObjectiveManager/RERANK_OBJECTIVE'
export const RERANK_OBJECTIVE_SUCCESS =
  'app/ObjectiveManager/RERANK_OBJECTIVE_SUCCESS'
export const RERANK_OBJECTIVE_ERROR =
  'app/ObjectiveManager/RERANK_OBJECTIVE_ERROR'

export const OBJECTIVE_RANK = {
  FIRST: 'FIRST',
  LAST: 'LAST',
  UP: 'UP',
  DOWN: 'DOWN',
}
