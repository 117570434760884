import { all, put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'

import { getClient } from 'apolloClient'
import { fetchObjectives } from 'containers/SonraiData/actions'
import {
  createObjectiveSuccess,
  createObjectiveError,
  updateObjectiveSuccess,
  updateObjectiveError,
  deleteObjectiveError,
  deleteObjectiveSuccess,
  rerankObjectiveError,
  rerankObjectiveSuccess,
} from './actions'
import {
  CREATE_OBJECTIVE,
  UPDATE_OBJECTIVE,
  DELETE_OBJECTIVE,
  RERANK_OBJECTIVE,
} from './constants'

function* createObjective(action) {
  //Remove extra props from the input, only matters on a clone
  const objective = _.pick(action.payload, ['srn', 'name', 'description'])
  objective.definedControlFrameworks = (
    action.payload.definedControlFrameworks || []
  ).map(conf => _.pick(conf, ['controlFrameworkSrn', 'controlFrameworkOrder']))

  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation create_SonraiObjective(
          $objective: CreateSonraiObjectiveInput!
        ) {
          CreateSonraiObjectives(input: [$objective]) {
            items {
              srn
              name
            }
          }
        }
      `,
      variables: {
        objective: objective,
      },
    })

    if (result.data.CreateSonraiObjectives.items === null) {
      throw new Error('Received null response')
    }

    yield put(fetchObjectives())
    yield put(createObjectiveSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error creating objective', e)
    yield put(createObjectiveError())
  }
}

function* updateObjective(action) {
  //Remove extra props from the input to the update
  const objective = _.pick(action.payload, ['srn', 'name', 'description'])
  objective.definedControlFrameworks = (
    action.payload.definedControlFrameworks || []
  ).map(conf => _.pick(conf, ['controlFrameworkSrn', 'controlFrameworkOrder']))

  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation update_SonraiObjective(
          $objective: UpdateSonraiObjectiveInput!
        ) {
          UpdateSonraiObjectives(input: [$objective]) {
            items {
              srn
              name
            }
          }
        }
      `,
      variables: {
        objective: objective,
      },
    })

    if (result.data.UpdateSonraiObjectives.items === null) {
      throw new Error('Received null response')
    }

    yield put(fetchObjectives())
    yield put(updateObjectiveSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating objective', e)
    yield put(updateObjectiveError())
  }
}

function* deleteObjective(action) {
  const srn = action.payload

  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation delete_SonraiObjective($srn: String!) {
          DeleteSonraiObjectives(input: [{ srn: $srn }]) {
            items {
              srn
              deleted
            }
          }
        }
      `,
      variables: {
        srn: srn,
      },
    })

    if (
      result.data.DeleteSonraiObjectives.items === null ||
      !_.get(result, ['data', 'DeleteSonraiObjectives', 'items', 0, 'deleted'])
    ) {
      throw new Error('Received null response')
    }

    yield put(fetchObjectives())
    yield put(deleteObjectiveSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error deleting objective', e)
    yield put(deleteObjectiveError())
  }
}

function* rerankObjective(action) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation rank_SonraiObjective($ranking: RerankObjectiveInput!) {
          RerankObjective(input: $ranking) {
            srn
            objectiveRank
          }
        }
      `,
      variables: {
        ranking: action.payload,
      },
    })

    if (
      result.data.RerankObjective === null ||
      !_.get(result, ['data', 'RerankObjective', 'srn'])
    ) {
      throw new Error('Received null response')
    }

    yield put(fetchObjectives())
    yield put(rerankObjectiveSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error ranking the objective', e)
    yield put(rerankObjectiveError())
  }
}

function* objectiveManagerSaga() {
  yield all([
    takeLatest(CREATE_OBJECTIVE, createObjective),
    takeLatest(UPDATE_OBJECTIVE, updateObjective),
    takeLatest(DELETE_OBJECTIVE, deleteObjective),
    takeLatest(RERANK_OBJECTIVE, rerankObjective),
  ])
}

export default objectiveManagerSaga
